/* You can add global styles to this file, and also import other style files */
@import '@work4all/assets/theme/global.scss';

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body,
#root {
  height: 100%;
  font-size: 14px;
  background-color: var(--ui03);
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

#loadingScreen {
  background-color: var(--brand01);
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: white;

  #logo {
    width: 3rem;
    height: 3rem;
    position: absolute;
  }

  &:after {
    content: '';
    display: block;
    border-top: 2px solid white;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    animation: rotating 1s linear infinite;
  }
}

.snackbar-container {
  bottom: calc(5rem + var(--w4a-safe-area-bottom, 0px)) !important;
}

#react-refresh-overlay,
#webpack-dev-server-client-overlay {
  top: var(--w4a-safe-area-top, 0px) !important;
  height: calc(
    100vh - var(--w4a-safe-area-top, 0px) - var(--w4a-safe-area-bottom, 0px)
  ) !important;
  min-height: unset !important;
  left: var(--w4a-safe-area-left, 0px) !important;
  width: calc(
    100vw - var(--w4a-safe-area-left, 0px) - var(--w4a-safe-area-right, 0px)
  ) !important;
}
