@use './mixins.scss';
@use './themes.scss';

$mbsc-ios-accent: #499ce0;
$mbsc-material-accent: #499ce0;

@import './fonts/index.scss';
@import './pagedjs.scss';
@import '~@mobiscroll/react/dist/css/mobiscroll.scss';

@include mixins.custom-scrollbar;

@include themes.theme-common;
@include themes.theme-light;
@include themes.theme-dark;

body * {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.mbsc-calendar-wrapper {
  width: unset;
  min-width: 20rem;
}
