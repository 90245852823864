@media screen {
  .pagedjs_sheet,
  .pagedjs_page,
  .pagedjs_pagebox {
    width: 100% !important;
  }

  .pagedjs_pages,
  .pagedjs_page,
  .pagedjs_sheet,
  .pagedjs_pagebox {
    display: block !important;
    height: 100% !important;
  }

  .pagedjs_margin-top-left-corner-holder,
  .pagedjs_margin-top,
  .pagedjs_margin-top-right-corner-holder,
  .pagedjs_margin-right,
  .pagedjs_margin-left,
  .pagedjs_margin-bottom-left-corner-holder,
  .pagedjs_margin,
  .pagedjs_margin-bottom,
  .pagedjs_margin-bottom-left-corner,
  .pagedjs_margin-bottom-right-corner-holder {
    display: none !important;
  }

  .pagedjs_page_content {
    column-width: unset !important;
  }

  .pagedjs_page_content > div {
    height: 100%;
  }
}
