@use './spacing.scss';

@mixin theme-common {
  [data-theme] {
    --brand01: var(--mui-palette-brand01);
    --brand02: var(--mui-palette-brand02);
    --brand03: var(--mui-palette-brand03);

    --text01: var(--mui-palette-text-primary);
    --text02: var(--mui-palette-text-secondary);
    --text03: var(--mui-palette-text-tertiary);
    --textInverse: var(--mui-palette-text-inverse);

    --ui01: var(--mui-palette-ui1-main);
    --ui02: var(--mui-palette-ui2-main);
    --ui03: var(--mui-palette-ui3-main);
    --ui04: var(--mui-palette-ui4-main);
    --ui05: var(--mui-palette-ui5-main);
    --ui06: var(--mui-palette-ui6-main);
    --ui07: var(--mui-palette-ui7-main);
    --ui08: var(--mui-palette-ui8-main);

    --userShade1: var(--mui-palette-userShade1);
    --userShade2: var(--mui-palette-userShade2);
    --userShade3: var(--mui-palette-userShade3);
    --userShade4: var(--mui-palette-userShade4);
    --userShade5: var(--mui-palette-userShade5);
    --userShade6: var(--mui-palette-userShade6);
    --userShade7: var(--mui-palette-userShade7);
    --userShade8: var(--mui-palette-userShade8);
    --userShade9: var(--mui-palette-userShade9);

    --success: var(--mui-palette-success-main);
    --error: var(--mui-palette-error-main);
    --widgetBorder: var(--mui-palette-widgetBorder);
    --mentionBg: #499ce013;

    --labeledInputHeight: #{spacing.$spaceXL};
    --projectSearchBarHeight: #{spacing.$spaceXL};
    --headerHeight: 4rem;
    --mobileNavigationHeight: #{spacing.$spaceXXL};
    --projectInfoHeight: 4rem;
    --metaCardsHeight: 4.5rem;
    --commandBarHeight: #{spacing.$spaceXL};
    --basicTableCellPadding: 0.5rem;
    --popoverFilterContentWidthRem: 21rem;
    --cardWidgetRowHeightRem: #{spacing.$spaceL};
    --cardWidgetColMinWidthRem: #{spacing.$spaceL};
    --cardWidgetHeaderHeight: 3rem;
    --borderRadius: 8px;
    --modalMarginThreshold: 2.5rem;
  }
}

@mixin theme-light {
  [data-theme='light'] {
    --topBarBg: #499ce0;
    --gray06: #f2f2f2;
    --purple: #bb36ae;
    --alert: #d9a931;
    --errorLight: #f9e5e1;
    --uiInput: #eeeeee;

    --bgShade1: #dceffe;
    --bgShade2: #dbf5c6;
    --bgShade3: #fcedc7;
    --bgShade4: #fbd8d0;
    --bgShade5: #f9e0fd;
    --bgShade6: #f0f1f3;

    --elevationColor1: rgba(0, 0, 0, 0.08);
    --elevationColor2: rgba(0, 0, 0, 0.2);
    --elevationColor3: rgba(0, 0, 0, 0.2);
    --elevationColor4: rgba(0, 0, 0, 0.2);
    --elevationColor5: rgba(0, 0, 0, 0.2);
    --elevationColor6: rgba(0, 0, 0, 0.2);
    --elevationColor7: rgba(0, 0, 0, 0.2);
    --elevationColor8: rgba(0, 0, 0, 0.2);

    --projectPrimaryLightColor: #5fa8e4;
  }
}

@mixin theme-dark {
  [data-theme='dark'] {
    --topBarBg: #31486b;
    --gray06: #5e5e5e;
    --purple: #631c5c;
    --alert: #917c25;
    --errorLight: #5b231e;
    --uiInput: #2c2e36;

    --bgShade1: #37557f;
    --bgShade2: #538e3c;
    --bgShade3: #978544;
    --bgShade4: #7c4457;
    --bgShade5: #4d387d;
    --bgShade6: #475384;

    --elevationColor1: rgba(0, 0, 0, 0.3);
    --elevationColor2: rgba(0, 0, 0, 0.4);
    --elevationColor3: rgba(0, 0, 0, 0.5);
    --elevationColor4: rgba(0, 0, 0, 0.6);
    --elevationColor5: rgba(0, 0, 0, 0.7);
    --elevationColor6: rgba(0, 0, 0, 0.8);
    --elevationColor7: rgba(0, 0, 0, 0.9);
    --elevationColor8: rgba(0, 0, 0, 1);

    --projectPrimaryLightColor: #4284bf;
  }
}
